import { template as template_25400c8d5b874c01a2b41967602569d1 } from "@ember/template-compiler";
const FKControlMenuContainer = template_25400c8d5b874c01a2b41967602569d1(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
