import { template as template_1026cb472b724b4485066380e4e891fb } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_1026cb472b724b4485066380e4e891fb(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
